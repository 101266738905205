/**
 * @typedef {Object} ApiResponse
 * @property {String} status ’ok‘ or ’error‘
 * @property {Number} code HTTP response status code
 * @property {String} [message] General error message or success message
 * @property {String} [key] Error key
 * @property {Object} [details] Keys are field names, values are error messages
 */

class MForm {
  /**
   * @param {HTMLFormElement} element
   */
  constructor(element) {
    this.element = element;
    this.action = element.action;
    this.method = element.method;
    this.submitElement = null;

    this.successElement = element.querySelector('.m-form__success');
    this.errorElement = element.querySelector('.m-form__error');

    element.addEventListener('submit', this.onSubmit.bind(this));

    this.loadSpamPrevention();
  }

  /** @param {Boolean} value */
  set loading(value) {
    this.element.setAttribute('data-loading', value);
  }

  /** @type {Boolean} */
  get loading() {
    return this.element.getAttribute('data-loading') === 'true';
  }

  /** @param {Boolean} value */
  set disabled(value) {
    this.submitElement.toggleAttribute('disabled', value);
    this.submitElement.toggleAttribute('data-loader', value);
    [...this.element.elements].forEach((_) => {
      if (_ !== this.submitElement) {
        _.toggleAttribute('readonly', value);
      }
    });
    this.element.setAttribute('data-disabled', value);
  }

  /** @type {Boolean} */
  get disabled() {
    return this.element.getAttribute('data-disabled') === 'true';
  }

  removeFormErrors() {
    this.errorElement.innerText = '';
    this.errorElement.hidden = true;
    [...this.element.querySelectorAll('.a-field__error')].forEach((errorElement) => {
      Object.assign(errorElement, {
        hidden: true,
        innerText: '',
      });
    });
  }

  /** @param {Object} details Keys are field names, values are error messages */
  showFormErrors(details) {
    Object.keys(details).forEach((key) => {
      const inputElement = this.element.elements.namedItem(key);
      if (inputElement instanceof Element) {
        const errorElement = inputElement.closest('.a-field')?.querySelector('.a-field__error');
        if (errorElement) {
          errorElement.innerText = details[key];
          errorElement.hidden = false;
        }
      }
    });
  }

  /** @param {String} message */
  /** @param {Element} submitter */
  showSuccessMessage(message) {
    this.submitElement.toggleAttribute('hidden', true);

    this.successElement.innerText = message;
    this.successElement.hidden = false;
    this.successElement.focus();
  }

  async loadSpamPrevention() {
    const response = await fetch('/api/form-request/spam-prevention', {
      method: 'get',
    });

    const json = await response.json();

    Object.keys(json).forEach((key) => {
      const hiddenInputElement = Object.assign(document.createElement('input'), {
        name: key,
        value: json[key],
        type: 'hidden',
      });
      this.element.appendChild(hiddenInputElement);
    });
  }

  /** @param {Event} event */
  async onSubmit(event) {
    event.preventDefault();
    this.submitElement = event.submitter;

    try {
      const formData = new FormData(this.element);

      this.removeFormErrors();

      this.loading = true;
      this.disabled = true;

      const response = await fetch(this.action, {
        method: this.method,
        body: formData,
      });

      /** @type {ApiResponse} */
      const json = await response.json();

      if (json.status === 'ok') {
        this.showSuccessMessage(json.message ?? 'Success');
      } else if (json.key === 'form-errors') {
        this.disabled = false;
        this.showFormErrors(json.details);
      } else {
        throw new Error(json.message ?? 'Fatal Error');
      }
    } catch (/** @type {Error} */ error) {
      this.disabled = false;
      this.errorElement.innerText = error.message;
      this.errorElement.hidden = false;
    } finally {
      this.loading = false;
    }
  }
}

document.querySelectorAll('.m-form').forEach((element) => new MForm(element));
