const gridElement = document.querySelector('.GRID');

if (gridElement) {
  window.addEventListener('keydown', (event) => {
    const { key, ctrlKey } = event;
    if (key === 'l' && ctrlKey) {
      gridElement.hidden = !gridElement.hidden;
    }
  });
}
