import './a-field--location';

const elements = document.querySelectorAll('.m-trip-request');

elements.forEach((element) => {
  const formElement = element.querySelector('form');
  const fieldFromElement = element.querySelector('.a-field[data-name="from"]');
  const inputFromElement = element.querySelector('input[name="from"]');
  const inputfromDataElement = element.querySelector('input[name="fromData"]');
  const fieldToElement = element.querySelector('.a-field[data-name="to"]');
  const inputToElement = element.querySelector('input[name="to"]');
  const inputtoDataElement = element.querySelector('input[name="toData"]');
  const buttonSwitchDepartureDestinationPosition = element.querySelector('button[data-action="switchDepartureDestination"]');
  const dateInputElement = element.querySelector('input[name="date"]');
  const timeInputElement = element.querySelector('input[name="time"]');
  const startDeltaInputElements = element.querySelectorAll('input[name="startDelta"]');
  let startDelta = formElement.elements.startDelta.value;

  // functions
  const padTo2Digits = (num) => num.toString().padStart(2, '0');
  const updateDateTime = () => {
    let date = new Date();
    switch (startDelta) {
      case 'now': {
        break;
      }
      case 'in-15-min': {
        date.setMinutes(date.getMinutes() + 15);
        break;
      }
      case 'in-30-min': {
        date.setMinutes(date.getMinutes() + 30);
        break;
      }
      default: {
        date = null;
      }
    }

    if (date) {
      dateInputElement.value = [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-');
      timeInputElement.value = [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
      ].join(':');
    }
  };
  const updateStartDelta = () => {
    const dateFormat = {
      dateStyle: 'short',
      timeStyle: 'short',
    };
    const inputDate = (new Date(`${dateInputElement.value} ${timeInputElement.value}`)).toLocaleString('de-DE', dateFormat);
    const now = new Date();
    const in15Min = new Date((new Date()).setMinutes(now.getMinutes() + 15));
    const in30Min = new Date((new Date()).setMinutes(now.getMinutes() + 30));
    switch (inputDate) {
      case now.toLocaleString('de-DE', dateFormat): {
        formElement.elements.now.checked = true;
        startDelta = formElement.elements.now.value;
        break;
      }
      case in15Min.toLocaleString('de-DE', dateFormat): {
        formElement.elements['in-15-min'].checked = true;
        startDelta = formElement.elements['in-15-min'].value;
        break;
      }
      case in30Min.toLocaleString('de-DE', dateFormat): {
        formElement.elements['in-30-min'].checked = true;
        startDelta = formElement.elements['in-30-min'].value;
        break;
      }
      default: {
        formElement.elements.now.checked = false;
        formElement.elements['in-15-min'].checked = false;
        formElement.elements['in-30-min'].checked = false;
        startDelta = null;
      }
    }
  };

  // event listener
  buttonSwitchDepartureDestinationPosition.addEventListener('click', () => {
    const from = inputFromElement.value;
    const fromClassName = fieldFromElement.className;
    const fromData = inputfromDataElement.value;
    const to = inputToElement.value;
    const toClassName = fieldToElement.className;
    const toData = inputtoDataElement.value;
    inputFromElement.value = to;
    fieldFromElement.className = toClassName;
    inputfromDataElement.value = toData;
    inputToElement.value = from;
    fieldToElement.className = fromClassName;
    inputtoDataElement.value = fromData;

    inputFromElement.dispatchEvent(new Event('automaticInput', {
      bubbles: true,
      cancelable: true,
    }));
    inputToElement.dispatchEvent(new Event('automaticInput', {
      bubbles: true,
      cancelable: true,
    }));

    /* eslint-disable-next-line no-underscore-dangle */
    window._paq?.push(['trackEvent', 'Verbindungsauskunft', 'Click', 'Switch Departure Destination']);
  });
  dateInputElement.addEventListener('input', updateStartDelta);
  timeInputElement.addEventListener('input', updateStartDelta);
  dateInputElement.addEventListener('change', updateStartDelta);
  timeInputElement.addEventListener('change', updateStartDelta);
  startDeltaInputElements.forEach((startDeltaInputElement, index) => {
    startDeltaInputElement.addEventListener('click', () => {
      startDelta = startDeltaInputElement.value;
      updateDateTime();

      /* eslint-disable-next-line no-underscore-dangle */
      window._paq?.push(['trackEvent', 'Verbindungsauskunft', 'Click', 'in … min', index]);
    });
  });

  // init
  if (dateInputElement.value === '' && timeInputElement.value === '') {
    updateDateTime();
  }
  updateStartDelta();
  setInterval(updateDateTime, 15000); // every 15 seconds
});
