class Helper {
  static convertISO8601ToString(string) {
    // adoption of https://stackoverflow.com/questions/19061360/how-to-convert-youtube-api-duration-iso-8601-duration-in-the-format-ptms-to
    if (string) {
      let hours; let minutes;

      if (string.includes('H')) {
        hours = string.slice(2, string.indexOf('H'));
      } else {
        hours = false;
      }

      // determines how minutes are displayed, based on existence of hours and minutes
      if (hours) {
        if (string.includes('M')) {
          if (string.indexOf('M') - string.indexOf('H') === 3) {
            minutes = string.slice(string.indexOf('H') + 1, string.indexOf('M'));
          } else {
            minutes = `0${string.charAt(string.indexOf('M') - 1)}`;
          }
        } else {
          minutes = '00';
        }
      } else if (string.includes('M')) {
        minutes = string.slice(2, (string.indexOf('M')));
      } else {
        minutes = '0';
      }

      // distinction because livestreams (P0D) are not considered
      return string === 'P0D' ? 'Live' : `${hours ? `${hours}&thinsp;h ` : ''}${minutes}&thinsp;min`;
    }

    return null;
  }

  static formatPrice(price) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price);
  }

  static getBusLineColors(lineNumber) {
    const lineColorsElement = document.getElementById('line-colors');
    const lines = JSON.parse(lineColorsElement.innerText);
    const line = lines?.find((item) => item.number === lineNumber);
    return {
      background: line?.color,
      text: line?.fontcolor,
    };
  }

  static getALeg(tripLeg, modeName, showModeName = false) {
    const service = tripLeg['trias:TimedLeg']?.['trias:Service'];
    const id = tripLeg['trias:LegId'];
    let lineName = '';
    let mode = '';
    let submode = null;
    let style = '';

    if (service) {
      lineName = service?.['trias:ServiceSection']?.['trias:PublishedLineName']?.['trias:Text'];
      const triasMode = service?.['trias:ServiceSection']?.['trias:Mode'];
      mode = triasMode?.['trias:PtMode'];
      if (mode === 'rail') {
        submode = triasMode?.['trias:RailSubmode'];
      } else if (mode === 'bus') {
        submode = triasMode?.['trias:BusSubmode'];
      }

      if (service['trias:ServiceSection']?.['trias:OperatorRef'] === 'tub:07') {
        const { background, text } = Helper.getBusLineColors(lineName);
        if (background && text) {
          style = `--background-color: ${background}; --color: ${text}`;
        }
      }
    }
    if (tripLeg['trias:InterchangeLeg'] || tripLeg['trias:ContinuousLeg']) {
      mode = 'walk';
    }

    lineName = lineName.replace('InterCityExpress', '');

    const spanElement = document.createElement('span');
    spanElement.classList.add('a-leg');
    spanElement.classList.add(`-${mode}`);
    if (submode) {
      spanElement.classList.add(`-${submode}`);
    }
    if (style) {
      spanElement.style = style;
    }
    spanElement.dataset.id = id;

    if (showModeName === true) {
      spanElement.innerHTML += `<span class="a-leg__mode">${modeName}</span>`;
    }
    if (mode === 'walk') {
      const icon = Helper.getIcon('walk');
      icon.setAttribute('aria-hidden', 'true');
      const leg = tripLeg['trias:InterchangeLeg'] ?? tripLeg['trias:ContinuousLeg'];
      const triasDuration = leg?.['trias:WalkDuration'] ?? leg?.['trias:Duration'];
      const duration = Helper.convertISO8601ToString(triasDuration);
      spanElement.innerHTML += `
        <span class="a-leg__number">
          <span class="a-visually-hidden">Fußweg </span>
          ${icon.outerHTML} ${duration}
        </span>`;
    } else {
      spanElement.innerHTML += `
        <span class="a-leg__number">
          ${showModeName === false ? `<span class="a-visually-hidden">${modeName} </span>` : ''}
          ${lineName}
        </span>`;
    }

    return spanElement;
  }

  static triasPositionToCoordinates(triasPosition) {
    if (triasPosition) {
      return triasPosition.map((item) => [parseFloat(item['trias:Longitude']), parseFloat(item['trias:Latitude'])]);
    }
    return [];
  }

  static triasLegTrackToCoordinates(legTrack) {
    const position = legTrack['trias:TrackSection']?.['trias:Projection']?.['trias:Position'];
    return this.triasPositionToCoordinates(position);
  }

  static triasNavigationPathToCoordinates(triasNavigationPath) {
    let coordinates = [];
    if (triasNavigationPath['trias:NavigationSection'].constructor === Object) {
      const position = triasNavigationPath['trias:NavigationSection']['trias:TrackSection']?.['trias:Projection']?.['trias:Position'];
      coordinates = [...coordinates, ...this.triasPositionToCoordinates(position)];
    } else if (triasNavigationPath['trias:NavigationSection'].constructor === Array) {
      triasNavigationPath['trias:NavigationSection']?.forEach((item) => {
        const position = item['trias:TrackSection']?.['trias:Projection']?.['trias:Position'];
        coordinates = [...coordinates, ...this.triasPositionToCoordinates(position)];
      });
    }
    return coordinates;
  }

  static isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  static getIcon(name) {
    return document.getElementById('icons')?.querySelector(`[data-name="${name}"]`);
  }
}

export default Helper;
