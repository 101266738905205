import Helper from './Helper';
import AField from './a-field';

class AFieldStop extends AField {
  constructor(element) {
    super(element);

    this.autocompleteListContainerElement = element.querySelector('.a-field__autocomplete-list-container');
    this.autocompleteErrorElement = this.autocompleteListContainerElement?.querySelector('.a-field__autocomplete-error');
    this.autocompleteListElement = this.autocompleteListContainerElement?.querySelector('.a-field__autocomplete-list');
    this.formElement = element.closest('form');

    this.valueWithFetchRequest = this.value;
    this.autocompleteFetchUrl = this.element.dataset.autocompleteFetch;
    this.inputAutocompleteValueElement = this.element.querySelector('input[data-autocomplete-value]');
    this.autocompleteData = [];
    this.isInteractingWithList = false;

    this.defaultValue = this.inputElement.value;
    this.localStorageKey = 'recentAutocompleteLocationItems';

    // events
    this.inputElement.addEventListener('click', () => {
      this.updateAutocompleteList();
    });
    this.inputElement.addEventListener('focus', () => {
      this.updateAutocompleteList();
      requestAnimationFrame(() => {
        this.inputElement.select();
      });
    });
    this.inputElement.addEventListener('blur', () => {
      this.toggleModifiers();
      if (this.isInteractingWithList !== true) {
        this.closeAutocompleteList();
        this.isInteractingWithList = false;
      }
    });
    this.autocompleteListElement.addEventListener('mousemove', (event) => {
      if (event.target.getAttribute('role') === 'option') {
        this.selectElement(event.target);
      }
    });
    this.autocompleteListElement.addEventListener('mousedown', () => {
      this.isInteractingWithList = true;
    });
    window.addEventListener('mouseup', (event) => {
      if (event.target.closest('.a-field') !== this.element) {
        this.closeAutocompleteList();
      } else {
        this.isInteractingWithList = false;
      }
    });
    this.autocompleteListElement.addEventListener('click', (event) => {
      if (event.target.getAttribute('role') === 'option') {
        this.selectElement(event.target);
        this.updateAutcompleteValue(event.target);
      }
    });
    this.element.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.openAutocompleteList();
        this.selectNext();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.selectPrev();
      } else if (event.key === 'Enter') {
        if (this.isAutocompleteOpen) {
          event.preventDefault();
          this.updateAutcompleteValue(this.autocompleteListElement.querySelector('[aria-selected="true"]'));
          this.closeAutocompleteList();
        }
      } else if (event.key === 'Escape') {
        this.closeAutocompleteList();
      }
    });
    this.formElement.addEventListener('submit', () => {
      if (this.formElement.checkValidity() === true && this.value !== '' && this.autocompleteValue) {
        const autocompleteValueObject = JSON.parse(this.autocompleteValue);
        if (autocompleteValueObject.id !== 'current-position') {
          this.updateLocalStorage(autocompleteValueObject);
        }
      }
    });

    // init
    this.toggleModifiers();
  }

  get autocompleteValue() {
    return this.inputAutocompleteValueElement?.value;
  }

  get isAutocompleteOpen() {
    return this.inputElement.getAttribute('aria-expanded') === 'true';
  }

  get deleteButton() {
    const deleteButtonElement = Object.assign(document.createElement('button'), {
      title: 'entfernen',
      innerHTML: Helper.getIcon('close-500')?.outerHTML,
    });
    deleteButtonElement.setAttribute('aria-hidden', 'true');
    deleteButtonElement.setAttribute('type', 'button');
    deleteButtonElement.setAttribute('tabindex', '-1');
    deleteButtonElement.dataset.action = 'removeItem';

    deleteButtonElement.addEventListener('click', () => {
      const liElement = deleteButtonElement.closest('li');
      if (liElement) {
        this.removeItemFromLocalStorageByValue(liElement.dataset.value);
        liElement.remove();
        this.inputElement.focus();
      }
    });

    return deleteButtonElement;
  }

  get recentAutocompleteItems() {
    return JSON.parse(window.localStorage.getItem(this.localStorageKey) ?? '[]');
  }

  get allowFetch() {
    return this.element.dataset.type !== 'geoPosition';
  }

  onInput() {
    this.updateAutocompleteList();
    this.element.dataset.type = '';
    if (this.inputAutocompleteValueElement) {
      this.inputAutocompleteValueElement.value = '';
      this.inputAutocompleteValueElement.disabled = true;
    }
    if (this.element.className !== this.defaultClassName) {
      this.element.className = this.defaultClassName;
    }
    super.onInput();
  }

  async updateAutcompleteValue(selectedElement) {
    if (selectedElement) {
      let shouldUpdateInputElemenet = true;
      if (selectedElement.id === 'current-position') {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((currentPosition) => {
              resolve(currentPosition);
            }, () => {
              reject(new Error('Ihre Position konnte nicht ermittelt werden.'));
            });
          });
          const value = JSON.parse(selectedElement.dataset.value);
          value.geoPosition = {
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
          };
          selectedElement.setAttribute('data-value', JSON.stringify(value));
        } catch (error) {
          shouldUpdateInputElemenet = false;
          this.showError(error.message);
        }
      }

      if (shouldUpdateInputElemenet === true) {
        this.inputElement.value = selectedElement.innerText.trim();
        if (selectedElement.dataset.value) {
          this.inputAutocompleteValueElement.disabled = false;
          this.inputAutocompleteValueElement.value = selectedElement.dataset.value;
        } else {
          this.inputAutocompleteValueElement.disabled = true;
          this.inputAutocompleteValueElement.value = null;
        }
        this.element.className = this.defaultClassName;
        if (selectedElement.dataset.modifier) {
          this.element.classList.add(selectedElement.dataset.modifier);
        }
      }
    }

    this.closeAutocompleteList();

    /* eslint-disable-next-line no-underscore-dangle */
    window._paq?.push(['trackEvent', 'a-field', 'selected', selectedElement.innerText]);
  }

  updateLocalStorage(location) {
    let { recentAutocompleteItems } = this;
    recentAutocompleteItems.unshift(location);
    recentAutocompleteItems = recentAutocompleteItems
      .filter((obj, index, arr) => arr.map((mapObj) => mapObj.id).indexOf(obj.id) === index)
      .slice(0, 5);
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(recentAutocompleteItems));
  }

  removeItemFromLocalStorageByValue(value) {
    const valueObject = JSON.parse(value);
    let { recentAutocompleteItems } = this;
    recentAutocompleteItems = recentAutocompleteItems
      .filter((item) => item.id !== valueObject.id);
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(recentAutocompleteItems));

    /* eslint-disable-next-line no-underscore-dangle */
    window._paq?.push(['trackEvent', 'a-field', 'remove item from recents', value.text ?? null]);
  }

  selectElement(element) {
    if (element) {
      const selectedElement = this.autocompleteListElement.querySelector('[aria-selected="true"]');
      if (selectedElement) {
        selectedElement.setAttribute('aria-selected', 'false');
      }
      element.setAttribute('aria-selected', 'true');
      this.inputElement.setAttribute('aria-activedescendant', element.id);
    }
  }

  selectNext() {
    const selectedElement = this.autocompleteListElement.querySelector('[aria-selected="true"]');
    let elementToSelect = null;
    if (selectedElement) {
      elementToSelect = selectedElement.nextElementSibling;
    } else {
      elementToSelect = this.autocompleteListElement.firstElementChild;
    }

    if (elementToSelect) {
      this.selectElement(elementToSelect);
    }
  }

  selectPrev() {
    const selectedElement = this.autocompleteListElement.querySelector('[aria-selected="true"]');
    let elementToSelect = null;
    if (selectedElement) {
      elementToSelect = selectedElement.previousElementSibling;
    }

    if (elementToSelect) {
      this.selectElement(elementToSelect);
    }
  }

  openAutocompleteList() {
    this.inputElement.setAttribute('aria-expanded', 'true');
  }

  closeAutocompleteList() {
    this.inputElement.setAttribute('aria-expanded', 'false');
    this.toggleModifiers();
  }

  clearAutocompleteList() {
    this.autocompleteListElement.innerHTML = '';
    this.closeAutocompleteList();
  }

  async fetchAutocomplete() {
    const { value } = this.inputElement;
    let encodedValue = encodeURIComponent(value);
    // Replace encoded slash with real slash, to work with Kirby API.
    encodedValue = encodedValue.replace('%2F', '/');
    let url = this.autocompleteFetchUrl;
    url = url.replace('{input}', encodedValue);
    url = new URL(url);

    // abort old fetch controller and create new
    this.fetchController?.abort();
    this.fetchController = new AbortController();

    const response = await fetch(url, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: this.fetchController.signal,
    });
    return response.json();
  }

  updateAutocompleteListElement() {
    const data = this.autocompleteData;
    let html = '';
    data.forEach((item) => {
      const {
        name,
        id,
        type,
        recent,
      } = item;
      const liElement = Object.assign(document.createElement('li'), {
        className: `-${type}`,
        role: 'option',
        ariaSelected: false,
        id,
        innerHTML: `<span>${name}</span>`,
      });
      if (recent === true) {
        liElement.appendChild(this.deleteButton);
      }
      liElement.dataset.value = JSON.stringify(item);
      html += liElement.outerHTML;
    });
    this.autocompleteListElement.innerHTML = html;
    this.autocompleteListElement.querySelectorAll('button[data-action="removeItem"]').forEach((removeItemButtonElement) => {
      removeItemButtonElement.addEventListener('click', () => {
        const liElement = removeItemButtonElement.closest('li');
        if (liElement) {
          this.removeItemFromLocalStorageByValue(liElement.dataset.value);
          liElement.remove();
          this.inputElement.focus();
        }
      });
    });
  }

  async updateAutocompleteList() {
    if (this.value === '') {
      this.autocompleteData = [];
      this.autocompleteData.push({
        name: 'Aktuelle Position',
        id: 'current-position',
        type: 'geoPosition',
      });

      // adds recent autocomplete items
      const recentAutocompleteItems = this.recentAutocompleteItems
        .map((location) => Object.assign(location, {
          recent: true,
        }));
      this.autocompleteData = [...this.autocompleteData ?? [], ...recentAutocompleteItems];
    } else if (this.autocompleteValue === '' && this.allowFetch && this.value.length >= 3) {
      // avoids multiple fetch request for same value
      if (this.value !== this.valueWithFetchRequest) {
        this.valueWithFetchRequest = this.value;
        const json = await this.fetchAutocomplete();
        const { data } = json;
        this.autocompleteData = data ? data.map((item) => item.location ?? null) : null;

        /* eslint-disable-next-line no-underscore-dangle */
        window._paq?.push(['trackEvent', 'a-field', 'autocomplete', this.value, data.length]);
      }
    } else {
      this.autocompleteData = [];
    }
    if ((this.autocompleteData?.length > 0 ?? false)
      && document.activeElement === this.inputElement) {
      this.updateAutocompleteListElement();
      this.openAutocompleteList();
      // this.selectNext();
    } else {
      this.closeAutocompleteList();
    }
  }

  toggleModifiers() {
    const autocompleteValue = this.autocompleteValue ?? '';
    const autocompleteValueObject = JSON.parse(autocompleteValue !== '' ? autocompleteValue : null);
    if (autocompleteValueObject) {
      this.element.setAttribute('data-type', autocompleteValueObject.type);
    }
    super.toggleModifiers();
  }
}

document.querySelectorAll('.a-field.-location').forEach((element) => new AFieldStop(element));
